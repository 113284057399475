(function () {
'use strict';

angular.module('hyresgastApp').controller('NewNegotiationCallCtrl', NewNegotiationCallCtrl);

function NewNegotiationCallCtrl(
  $scope, $location, $uibModal, $routeParams,
  NegotiationTypes, OrderedNegotiationTypes, ValidFileTypes, AttachmentTypes,
  NegotiationCall, RealEstate, Organization, User,
  ManualChange,
  DateLocal, Auth, HyUtils,
  DateCounter, Flash, ErrorHandler,
  Help
) {
  // ------------------------------------------------------------------------------------------------------------
  // In many places in this file, we deal with objects that can be either a property (fastighet) or an apartment.
  // For historical reasons, in most places the variables used are named as if these objects are always properties.
  // For example, vm.properties should really be called vm.propertiesOrApartments or something similar.
  // ------------------------------------------------------------------------------------------------------------
  const propertyTypes = [
    NegotiationTypes.ANNUAL_PROPERTY,
    NegotiationTypes.STANDARD_PROPERTY,
    NegotiationTypes.REBUILD_PROPERTY,
    NegotiationTypes.REBUILD_PROPERTY_2,
    NegotiationTypes.UTILITY_VALUE_PROPERTY,
  ];
  const apartmentTypes = [
    NegotiationTypes.STANDARD_APARTMENT,
    NegotiationTypes.REBUILD_APARTMENT,
    NegotiationTypes.UTILITY_VALUE_APARTMENT,
  ];

  var vm = this;
  vm.NegotiationTypes = OrderedNegotiationTypes;
  vm.isHgf = Auth.isHgfUser();
  vm.lastHeader = vm.isHgf ? 'Förhandsgranska och skapa' : 'Förhandsgranska och skicka in';
  vm.lastSubheader = vm.isHgf ? 'Skapa' : 'Skicka in';
  vm.lastButton = vm.isHgf ? 'Skapa' : 'Skicka';
  if (vm.isHgf) {
    vm.showAddNegotiatorLink = true;
    vm.partyDescription = 'hyresvärd/ombud';
  }

  vm.apartmentSelector = {
    fgetProperties: ManualChange.queryProperty,
    fgetApartments: ManualChange.queryApartments,
  };

  vm.saving = false;
  vm.attachments = [];
  vm.negotiationCall = {};
  vm.selectedPropertyIds = {};
  vm.properties = [];
  vm.readyOwners = {};
  vm.toggleOwnersList = {};
  vm.uploadedDocumentsList = [];
  vm.rentListAlreadyImported = false;
  vm.pageLimit = 30;
  vm.currentPage = 0;
  vm.firstRecordInCurrentPage = 1;

  vm.filePlateBuffers = [];

  vm.showNegotiationTypeInfo = showNegotiationTypeInfo;
  vm.isRealEstateChoiceMethodEnabled = isRealEstateChoiceMethodEnabled;
  vm.setRadioModelByOutsideClick = setRadioModelByOutsideClick;
  vm.searchProperties = searchProperties;
  vm.getPropertyOwners = getPropertyOwners;
  vm.addProperty = addProperty;
  vm.removeProperty = removeProperty;
  vm.editDemandAndMotivation = editDemandAndMotivation;
  vm.toggleAllPropertiesSelection = toggleAllPropertiesSelection;
  vm.updateAllPropertiesBoxState = updateAllPropertiesBoxState;
  vm.deleteUploadedFile = deleteUploadedFile;
  vm.deleteDocument = deleteDocument;
  vm.save = save;
  vm.showHelpProperties = (title, bodyText) => Help.renderHelpModal(title, bodyText);

  // used to disable 'Ange yrkande & motiv' button
  vm.atLeastOneSelectedPropertyId = () => Object.keys(vm.selectedPropertyIds)
    .some(propertyId => vm.selectedPropertyIds[propertyId]);
  vm.getColumnTitle = title => title instanceof Array ? title[0] : title.split(',')[0].trim();

  vm.getMotivationType = () => {
    let type = vm.isPropertyBased ? 'property' : (vm.isApartmentBased ? 'apartment' : 'bubkis');

    if (vm.isHgf && vm.realEstateChoiceMethod === 'DIRECT_APARTMENTS' && vm.emptyProperties && vm.emptyProperties.length === 0) {
      type = 'apartment';
    }

    return type;
  };

  vm.filterProperties = filterProperties;
  vm.sortProperties = sortProperties;

  vm.sortPropertyColumn = 'propertyDesignation';
  vm.sortPropertyTitle = 'Fastighetsbeteckning';
  vm.sortPropertyReverse = false;
  vm.listPropertyColumns = [{
    title: 'Ägare',
    column: Auth.isHgfUser() ? 'owners' : 'primaryOwner'
  },
  {
    title: 'Fastighetsbeteckning',
    column: 'propertyDesignation'
  },
  {
    title: 'Kommun',
    column: 'municipality'
  },
  {
    title: 'LM distrikt',
    column: 'district'
  },
  ];

  vm.listApartmentColumns = [{
    title: 'Ägare',
    column: 'owners'
  },
  {
    title: 'Kommun',
    column: 'municipality'
  },
  {
    title: 'Fastighetsbeteckning',
    column: 'propertyDesignation'
  },
  {
    title: 'Gatuadress',
    column: 'streetAddress'
  },
  {
    title: 'Nationella lägenhetsnr',
    column: 'nationalNo'
  },
  {
    title: 'Lägenhetsnr',
    column: 'ownerNo'
  },
  ];

  let setupDatepickers = () => {
    vm.incomingDateOptions = {
      minDate: null,
    };
    let rentEffectiveFromAdvance = vm.negotiationCall.type === propertyTypes[0] ? 90 : 0;
    vm.rentEffectiveFromDateOptions = {
      minDate: vm.isHgf ? null : DateCounter.getFutureDate(rentEffectiveFromAdvance),
      initDate: DateCounter.getFutureDate(rentEffectiveFromAdvance),
    };
    vm.negotiationDateDateOptions = {
      // get 5 working days after today
      minDate: vm.isHgf ? null : DateCounter.getFutureDate(5, true),
      noWeekends: true,
    };
  }

  function start() {
    if ($routeParams.id) {
      vm.editMode = true;
      getNegotiationCall();
    }
    //query organization
    Organization.query()
      .$promise.then(function(response) {
        vm.organizations = response.rows;
        if (vm.organizations.length === 1) {
          vm.selectedOrganizationName = response.rows[0].name;
          vm.negotiationCall.counterpartOrganizationId = response.rows[0].id;
        }
      })
      .catch(function() {
        new ErrorHandler('Misslyckades att hämta organisation.');
      });
    //negotiator for HGF-type call
    User.queryOp({'filters[party]': 0})
      .$promise.then(result => {
        vm.users = result.rows.filter(u => u.organizations && u.organizations.length);
        //vi har template från nya-direkt
        //bättre göra komponent med deltagarval och chefstaggning
        vm.negotiation = { participants: [] };
      });
    setupDatepickers();
  }
  start();


  $scope.$watch('vm.negotiationCall.type', function() {
    // set default choice mode to 'RENT_LIST' if there are more than 1 choice available
    if (!vm.editMode) {
      var activeRealEstateChoiceMethods = Object.keys(
        getRealEstateChoiceMethodsByNegotiationType(vm.negotiationCall.type)
      );
      if (activeRealEstateChoiceMethods.length > 1) {
        let bestand = vm.isHgf ? 'DIRECT_APARTMENTS' : 'DIRECT_PROPERTIES';
        vm.realEstateChoiceMethod = activeRealEstateChoiceMethods.includes('DIRECT_APARTMENTS')
          ? bestand
          : 'RENT_LIST';
      } else {
        vm.realEstateChoiceMethod = activeRealEstateChoiceMethods[0];
      }
    }
    setupDatepickers();
    vm.isPropertyBased = propertyTypes.includes(vm.negotiationCall.type);
    vm.isApartmentBased = apartmentTypes.includes(vm.negotiationCall.type);
  });

  // The wizard uses a file upload directive and the directive is validating the content
  // of uploaded files (excel). If the file upload directive receives errors from the backend
  // we want to open a modal to show the errors to the user
  $scope.$watch('vm.validationErrors', function(errors) {
    if (errors && errors.status==='error') {
      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title-top',
        ariaDescribedBy: 'modal-body-top',
        size: 'md',
        index: 1075,
        // Template is also used in negotiation call to keep code dry
        templateUrl: 'resources/rent-list-modals/_file-validation-error-modal.html',
        controller: 'FileValidationResultModalCtrl',
        controllerAs: 'vm',
        resolve: {
          validationErrors: function() {
            return errors;
          },
          validationResponse: function() {
            return null;
          },
          realEstateChoiceMethod: function () {
            return vm.realEstateChoiceMethod;
          }
        },
        windowClass: 'modal-content--green'
      });
    }
  });

  $scope.$watch('vm.validationResponse', function(response) {
    if (response && response.status==='ok' && (response.numNotExistingBuildings > 0
      || response.numNotExistingApartments > 0 || response.numMisspelledAddresses > 0)) {

      vm.hideApartmentCounter = vm.realEstateChoiceMethod !== 'RENT_LIST';

      $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title-top',
        ariaDescribedBy: 'modal-body-top',
        size: 'md',
        index: 1075,
        templateUrl: 'resources/rent-list-modals/_file-validation-real-estate-modal.html',
        controller: 'FileValidationResultModalCtrl',
        controllerAs: 'vm',
        resolve: {
          validationResponse: function() {
            return response;
          },
          validationErrors: function() {
            return null;
          },
          realEstateChoiceMethod: function () {
            return vm.realEstateChoiceMethod;
          }
        },
        windowClass: 'modal-content--green'
      });
    }
  });

  $scope.$watch('vm.attachmentBlobId', function(blobId) {
    if (blobId) {
      vm.uploadedDocumentsList.push({
        blobId: blobId,
        fileName: vm.uploaderOptionalDocuments.currentFileName
      });
      // After upload of file we have to do cancelUpload on uploader
      // This makes the uploader ready for another upload.
      Flash.set('Lyckades lägga till dokumentet', 'success');
      vm.uploaderOptionalDocuments.cancelUpload();
    }
  });

  $scope.$on('$routeUpdate', function() {
    if ($location.search().step === 'properties') {
      vm.demandsCreated = false;
      // fetch properties in edit mode and set the flag vm.directPropertiesMode = true,
      // which plays similar role for the acknowledge modal as uploaded list's blobId.
      // call only once
      if (vm.editMode && !vm.fetchedPropertyList && vm.realEstateChoiceMethod === 'DIRECT_PROPERTIES') {
        vm.fetchedPropertyList = true;
        getProperties();
      }
    }

    // TODO: add another conditions for 'PROPERTY_LIST' and 'OPTIONAL_DOCUMENTS'
    if ($location.search().step === 'property-motivation' && !vm.fetchedPropertyList) {
      vm.allPropertiesBoxChecked = true;
      vm.sortPropertyColumn = 'propertyDesignation';
      vm.sortPropertyTitle = 'Fastighetsbeteckning';
      vm.sortPropertyReverse = false;
      vm.properties.forEach(property => {
        property.filtered = true;
        vm.selectedPropertyIds[property.id] = true;
      });
      if (vm.editMode && vm.rentListAlreadyImported) {
        vm.fetchedPropertyList = true;
        getProperties();
      } else if (vm.realEstateChoiceMethod === 'RENT_LIST' || vm.realEstateChoiceMethod === 'PROPERTY_LIST') {
        // fetch properties from rent-list (only once)
        getPropertyList();
      } else if (vm.realEstateChoiceMethod === 'DIRECT_APARTMENTS' && !vm.demandsCreated) {
        // fetch empty properties from apartments selector
        getPropertiesFromSelector();
      }
    }

    if ($location.search().step === 'apartment-motivation' && !vm.fetchedApartmentList) {
      vm.allPropertiesBoxChecked = true;
      vm.sortPropertyColumn = 'propertyDesignation';
      vm.sortPropertyTitle = 'Fastighetsbeteckning';
      vm.sortPropertyReverse = false;
      if (vm.editMode && vm.rentListAlreadyImported) {
        vm.fetchedApartmentList = true;
        getApartments();
      } else if (vm.realEstateChoiceMethod === 'RENT_LIST') {
        // fetch apartments from rent-list (only once)
        getApartmentList();
      } else if (vm.realEstateChoiceMethod === 'DIRECT_APARTMENTS' && !vm.demandsCreated) {
        getRealEstateFromSelector();
      }
    }

    if ($location.search().step === 'properties-preview-and-submit') {
      getPossibleErrors('properties');
    }

    if ($location.search().step === 'apartments-preview-and-submit') {
      getPossibleErrors('apartments');
      //TODO: apartment summary from backend here
    }
  });

  function deleteDocument(index) {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'xsm',
      templateUrl: 'components/modal/confirmation/confirmationModal.html',
      controller: 'ConfirmationModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--secondary'
    });

    modalInstance.result.then(function() {
      vm.uploadedDocumentsList.splice(index, 1);
      Flash.set('Lyckades ta bort dokumentet', 'success');
    });
  }

  // used in edit mode to select previous choice if more than 1 available
  function initializeRealEstateChoiceMethod() {
    if (vm.negotiationCall.rentListBlobId) {
      vm.realEstateChoiceMethod = 'RENT_LIST';
    } else if (vm.negotiationCall.propertyListBlobId) {
      vm.realEstateChoiceMethod = 'PROPERTY_LIST';
    } else if (vm.negotiationCall.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY) {
      vm.realEstateChoiceMethod = 'OPTIONAL_DOCUMENTS';
    } else {
      vm.realEstateChoiceMethod = 'DIRECT_PROPERTIES';
      vm.directPropertiesMode = true;
    }
  }

  // in edit mode
  function getRentListPreview() {
    vm.ready = false;

    return NegotiationCall.queryRentListValidation({
      blobId: vm.negotiationCall.rentListBlobId
    })
      .$promise.then(function(response) {
        vm.validationResponse = response;
        vm.rentListAlreadyImported = true;
      })
      .catch(new ErrorHandler('Misslyckades att kontrollera status för hyreslistan.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  // in edit mode
  function getPropertyListPreview() {
    vm.ready = false;

    return NegotiationCall.queryPropertyListValidation({
      blobId: vm.negotiationCall.propertyListBlobId
    })
      .$promise.then(function(response) {
        vm.validationResponse = response;
        vm.rentListAlreadyImported = true;
      })
      .catch(new ErrorHandler('Misslyckades att kontrollera status för fastighetslistan.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  // in edit mode
  function getNegotiationCall() {
    vm.ready = false;
    return NegotiationCall.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.negotiationCall = response;
        // XXX hack alert!
        // The rent file blob name is a path - 'rent-file/<GUID, 36 characters>'.
        // The slash is sent URL-encoded (i.e. as %2F), but on Azure IIS attempts to be 'helpful' by
        // url-decoding it before the request hits NodeJS, so the API gets confused about routing.
        // We work around this by replacing the slash by ___ and letting backend translate back again.
        // Reinventing wheels!
        vm.negotiationCall.rentListBlobId = response.rentFile
          ? response.rentFile.blobContainer + ':' + response.rentFile.blobName.replace('/', '___')
          : null;
        vm.negotiationCall.propertyListBlobId = response.propertyFile
          ? response.propertyFile.blobContainer + ':' + response.propertyFile.blobName.replace('/', '___')
          : null;
        vm.selectedOrganizationName = response.counterpart.name;

        vm.isPropertyBased = propertyTypes.includes(vm.negotiationCall.type);
        vm.isApartmentBased = apartmentTypes.includes(vm.negotiationCall.type);

        vm.rentEffectiveFrom = new Date(vm.negotiationCall.rentEffectiveFrom);
        initializeRealEstateChoiceMethod();
        if (vm.negotiationCall.rentListBlobId) {
          return getRentListPreview();
        } else if (vm.negotiationCall.propertyListBlobId) {
          return getPropertyListPreview();
        } else if (vm.negotiationCall.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY) {
          return getExistingDocuments();
        }
        return null;
      })
      .catch(new ErrorHandler('Misslyckades att hämta förhandlingsframställan.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function createDemand(property) {
    let isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

    let unitType = '2';
    property.demand = {};

    if (property.demandValue) {
      let valueWOpercent = property.demandValue.replace('%', '');
      let isNumber = isNumeric(valueWOpercent);

      if(isNumber) {
        unitType = '0'; //default unit type is procent
        property.demand.numericValue = parseFloat(valueWOpercent);
      } else {
        unitType = '2';
        property.demand.stringValue = property.demandValue;
      }

      if(property.demandValue.includes('%')) {
        unitType = '0';
      }

      property.demand.unit = unitType;
      property.demand.motivation = property.demandDescription;
    }
  }

  // fetch properties in edit mode when vm.realEstateChoiceMethod === 'DIRECT_PROPERTIES',
  // or when keeping the same rent list as previously
  function getProperties() {
    vm.readyProperties = false;
    return NegotiationCall.queryProperty(
      {
        id: $routeParams.id,
        include: 'demands'
      },
      {}
    )
      .$promise.then(function(response) {
        vm.properties = response.rows;
        vm.properties.forEach(function(property) {
          property.filtered = true;
          vm.selectedPropertyIds[property.id] = true;
        });
        updateAllPropertiesBoxState();
      })
      .catch(function() {
        new ErrorHandler('Misslyckades att hämta fastigheter.');
      })
      .finally(function() {
        vm.readyProperties = true;
      });
  }

  function getApartments() {
    vm.readyApartments = false;
    return NegotiationCall.queryApartment(
      {
        id: $routeParams.id,
        include: 'demands'
      },
      {}
    )
      .$promise.then(function(response) {
        vm.properties = response.rows;
        vm.properties.forEach(function(property) {
          property.filtered = true;
          vm.selectedPropertyIds[property.id] = true;
        });
        updateAllPropertiesBoxState();
      })
      .catch(new ErrorHandler('Misslyckades att hämta lägenheter.'))
      .finally(function() {
        vm.readyApartments = true;
      });
  }

  // fetch properties when (vm.realEstateChoiceMethod === 'RENT_LIST' || vm.realEstateChoiceMethod === 'PROPERTY_LIST')
  function getPropertyList() {
    var query;
    if (vm.realEstateChoiceMethod === 'RENT_LIST') {
      query = NegotiationCall.queryPropertyByRentList({
        blobId: vm.negotiationCall.rentListBlobId
      });
    } else if (vm.realEstateChoiceMethod === 'PROPERTY_LIST') {
      query = NegotiationCall.queryPropertyByPropertyList({
        blobId: vm.negotiationCall.propertyListBlobId
      });
    }
    vm.readyProperties = false;
    query.$promise.then(response => {
      vm.fetchedPropertyList = true;
      vm.properties = response.rows;
      vm.properties.forEach(property => {
        property.filtered = true;
        vm.selectedPropertyIds[property.id] = true;
        createDemand(property);
      });
      updateAllPropertiesBoxState();
    })
      .catch(() => { new ErrorHandler('Misslyckades att hämta fastighetslistan.') })
      .finally(function() { vm.readyProperties = true });
  }

  function getApartmentList() {
    vm.readyApartments = false;
    NegotiationCall.queryApartmentByRentList({
      blobId: vm.negotiationCall.rentListBlobId,
      include: 'demands'
    }).$promise.then(response => {
      vm.fetchedApartmentList = true;
      vm.properties = response.rows;
      vm.apartmentsTotalCount = response.count;
      setupAllPropertiesBoxState();
      updateAllPropertiesBoxState();
    })
      .catch(new ErrorHandler('Misslyckades att hämta lägenheter.'))
      .finally(() => { vm.readyApartments = true });
  }

  function getRealEstateFromSelector() {
    let apartments = vm.apartmentSelector.apartments;
    vm.properties = apartments;

    vm.apartmentsTotalCount = apartments.length;
    setupAllPropertiesBoxState();
    updateAllPropertiesBoxState();

    vm.propertySummary = {
      // eslint-disable-next-line no-undef
      numProperties: new Set(apartments.map(x => x.property)).size,
      // eslint-disable-next-line no-undef
      numMunicipalities: new Set(apartments.map(x => x.municipality)).size,
      // eslint-disable-next-line no-undef
      numOwners: new Set(apartments.map(x => x.property.owners.map(y => y.name)).flat()).size,
    };

    vm.readyApartments = true;
  }

  function getPropertiesFromSelector() {
    let properties = vm.emptyProperties;
    vm.properties = properties;

    vm.apartmentsTotalCount = 0;
    setupAllPropertiesBoxState();
    updateAllPropertiesBoxState();

    vm.propertySummary = {
      // eslint-disable-next-line no-undef
      numProperties: properties.length,
      // eslint-disable-next-line no-undef
      numMunicipalities: new Set(properties.map(x => x.municipality)).size,
      // eslint-disable-next-line no-undef
      numOwners: new Set(properties.map(x => x.owners).flat()).size,
    };

    vm.readyProperties = true;
  }

  function getPropertyOwners(propertyId) {
    let property = vm.properties.find(property => property.id === propertyId);

    if (!property.owners) {
      vm.readyOwners[property.id] = false;
      RealEstate.get({ id: propertyId }, {})
        .$promise.then(response => { property.owners = response.owners })
        .catch(new ErrorHandler('Misslyckades att hämta fastighetsägare.'))
        .finally(() => { vm.readyOwners[propertyId] = true });
    }
  }

  function getExistingDocuments() {
    vm.readyDocuments = false;
    NegotiationCall.listDocuments({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.uploadedDocumentsList = response.map(file => ({
          blobId: file.name,
          fileName: file.filename
        }));
      })
      .catch(new ErrorHandler('Misslyckades att hämta dokument.'))
      .finally(function() { vm.readyDocuments = true });
  }

  function getPossibleErrors(type) {
    vm.searching = true;

    let propertyIds = vm.properties.map(property => property.id);
    let ignoreCurrentCall = vm.editMode
      ? { callId: vm.negotiationCall.id }
      : null;

    if (type === 'properties') {
      return NegotiationCall.queryErrorsInProperties(ignoreCurrentCall, propertyIds)
        .$promise.then(function(response) {
          vm.possibleErrorsList = response.rows;
          if (vm.realEstateChoiceMethod !== 'DIRECT_APARTMENTS') {
            vm.propertySummary = response.propertySummary;
          }
        })
        .catch(new ErrorHandler('Misslyckades att hämta fel på fastigheter.'))
        .finally(function() {
          vm.searching = false;
        });
    } else if (type === 'apartments') {
      return NegotiationCall.queryErrorsInApartments(ignoreCurrentCall, propertyIds)
        .$promise.then(function(response) {
          vm.possibleErrorsList = response.rows;
          vm.apartmentSummary = response.apartmentSummary;
        })
        .catch(new ErrorHandler('Misslyckades att hämta fel på lägenheter.'))
        .finally(function() {
          vm.searching = false;
        });
    }
  }

  // info modal in basic page
  function showNegotiationTypeInfo() {
    $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'lg',
      index: 1075,
      templateUrl: 'components/modal/information/negotiationCallInfoModal.html',
      controller: 'InformationModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--green'
    });
  }

  function getRealEstateChoiceMethodsByNegotiationType(type) {
    switch (type) {
      case NegotiationTypes.ANNUAL_PROPERTY:
      case NegotiationTypes.STANDARD_PROPERTY:
      case NegotiationTypes.REBUILD_PROPERTY:
      case NegotiationTypes.REBUILD_PROPERTY_2:
      case NegotiationTypes.UTILITY_VALUE_PROPERTY:
        return {
          RENT_LIST: false,
          PROPERTY_LIST: true,
          DIRECT_PROPERTIES: true,
          DIRECT_APARTMENTS: true
        };
      case NegotiationTypes.STANDARD_APARTMENT:
      case NegotiationTypes.REBUILD_APARTMENT:
      case NegotiationTypes.UTILITY_VALUE_APARTMENT:
        return {
          RENT_LIST: true
        };
      case NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY:
        return {
          OPTIONAL_DOCUMENTS: true
        };
      case NegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT:
        return {
          DIRECT_PROPERTIES: true
        };
      default:
        return {};
    }
  }

  function isRealEstateChoiceMethodEnabled(realEstateChoiceMethod, negotiationType) {
    var activeRealEstateChoiceMethods = getRealEstateChoiceMethodsByNegotiationType(negotiationType);
    return activeRealEstateChoiceMethods[realEstateChoiceMethod];
  }

  function setRadioModelByOutsideClick(value) {
    // save the previous choice method
    var previousRealEstateChoiceMethod = vm.realEstateChoiceMethod;

    // set a new choice method, which can be canceled if not confirmed
    if (vm.isRealEstateChoiceMethodEnabled(value, vm.negotiationCall.type)) {
      vm.realEstateChoiceMethod = value;
    }

    if (
      (vm.negotiationCall.rentListBlobId && vm.realEstateChoiceMethod !== 'RENT_LIST') ||
      (vm.negotiationCall.propertyListBlobId && vm.realEstateChoiceMethod !== 'PROPERTY_LIST') ||
      (vm.directPropertiesMode && vm.realEstateChoiceMethod !== 'DIRECT_PROPERTIES')
    ) {
      // show acknowlegdement modal when we want to change the properties' source,
      // which leads to reseting the properties array filled in from the previous choice path
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title-top',
        ariaDescribedBy: 'modal-body-top',
        backdrop: 'static',
        size: 'md',
        index: 1075,
        templateUrl: 'resources/negotiation-call/new/modals/change-real-estate-source-modal.html',
        controller: 'BooleanConfirmationModalCtrl',
        controllerAs: 'vm',
        windowClass: 'modal-content--green'
      });
      modalInstance.result.then(function(acknowledgement) {
        // confirm the new choice
        if (acknowledgement) {
          vm.properties = [];
          vm.negotiationCall.rentListBlobId = null;
          vm.negotiationCall.propertyListBlobId = null;
          vm.fetchedPropertyList = false;
          vm.fetchedApartmentList = false;
          vm.negotiationCall.documentListBlobId = null;
          vm.directPropertiesMode = false;
          vm.rentListAlreadyImported = false;
          vm.selectedPropertyIds = {};
          vm.uploader.cancelUpload();
          vm.propertyFileUploader.cancelUpload();
        } else {
          // refuse the new choice
          vm.realEstateChoiceMethod = previousRealEstateChoiceMethod;
        }
      });
    }
  }

  function deleteUploadedFile() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'md',
      index: 1075,
      templateUrl: 'resources/negotiation-call/new/modals/delete-rent-list-modal.html',
      controller: 'ConfirmationModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--green'
    });
    modalInstance.result.then(function() {
      vm.uploader.cancelUpload();
      vm.propertyFileUploader.cancelUpload();
      vm.properties = [];
      vm.selectedPropertyIds = {};
      vm.negotiationCall.rentListBlobId = null;
      vm.negotiationCall.propertyListBlobId = null;
      vm.rentListAlreadyImported = false;
      vm.fetchedPropertyList = false;
      vm.fetchedApartmentList = false;
    });
  }

  // when vm.realEstateChoiceMethod === 'DIRECT_PROPERTIES'
  function searchProperties() {
    if (vm.inputTextComplex) {
      vm.toggleMenu = false;
      vm.searching = true;
      return RealEstate.querySimple({ 'search[complex]': vm.inputTextComplex }, {})
        .$promise.then(function(response) {
          vm.propertiesTypeahead = response.rows;
          if (vm.propertiesTypeahead.length > 0) {
            vm.toggleMenu = true;
          }
        })
        .catch(new ErrorHandler('Fel'))
        .finally(function() {
          vm.searching = false;
        });
    }
  }

  function addProperty(property) {
    vm.readyProperties = true;
    var foundProperty = vm.properties.filter(function(item) {
      return item.id === property.id;
    });

    // look through all properties to check if they had been added before or not
    if (foundProperty.length === 0) {
      vm.properties.push(property);
      vm.directPropertiesMode = true;
      vm.selectedPropertyIds[property.id] = false;
      updateAllPropertiesBoxState();
    }
  }

  function removeProperty(property) {
    var index = vm.properties.indexOf(property);
    // reset arrow state to closed (down) when remove-add the same
    vm.toggleOwnersList[property.id] = false;
    vm.properties.splice(index, 1);
    delete vm.selectedPropertyIds[property.id];
    updateAllPropertiesBoxState();
  }

  function editDemandAndMotivation() {
    vm.demandsCreated = true;
    let propertiesArr = vm.properties
      .filter(property => property.filtered && vm.selectedPropertyIds[property.id]);

    HyUtils.showDefaultModal('EditMotivationModalCtrl',
      'resources/negotiation-call/new/modals/edit-motivation-modal.html', {
        propertiesArr,
        negotiationType: vm.negotiationCall.type,
      }, demand => {
        vm.properties
          .filter(property => property.filtered && vm.selectedPropertyIds[property.id])
          .forEach(property => {
            angular.extend(property, { demand });
            vm.selectedPropertyIds[property.id] = false;
          });
        updateAllPropertiesBoxState();
      }, null, null, { size: 'md' });
  }

  function toggleAllPropertiesSelection() {
    vm.properties.forEach(function(property) {
      vm.selectedPropertyIds[property.id] = vm.allPropertiesBoxChecked;
    });
  }

  function setupAllPropertiesBoxState() {
    vm.properties.forEach(property => {
      property.filtered = true;
      vm.selectedPropertyIds[property.id] = true;
      createDemand(property);
    });
  }

  function updateAllPropertiesBoxState() {
    // auto select master checkbox if all other checkboxes are selected.
    // deselect master checkbox if at least one checkbox is not selected
    vm.allPropertiesBoxChecked = Object.keys(vm.selectedPropertyIds).every(function(propertyId) {
      return vm.selectedPropertyIds[propertyId];
    });
  }

  function save() {
    HyUtils.showDefaultModal('ReceivingOfficeModalCtrl',
      'resources/negotiation-call/new/modals/receiving-office-modal.html',
      { negotiationCallObj: vm.negotiationCall },
      receivingOfficeResult => {
        vm.negotiationCall.hgfOfficeId = receivingOfficeResult.selectedOffice.id;
        vm.negotiationCall.landlordSide = receivingOfficeResult.landlordSide;
        persistNegotiationCall();
      }, null, null, { size: 'sm' });
  }

  function persistNegotiationCall() {
    vm.saving = true;
    vm.negotiationCall.rentEffectiveFrom = DateLocal.fromUTCtoLocal(vm.rentEffectiveFrom);
    vm.negotiationCall.negotiationDate = DateLocal.fromUTCtoLocal(vm.negotiationDate);
    vm.negotiationCall.incomingDate = DateLocal.fromUTCtoLocal(vm.negotiationCall.incomingDate);
    vm.negotiationCall.attachments = vm.attachments;
    if (vm.readyProperties) {
      angular.extend(vm.negotiationCall, { properties: vm.properties });
    }

    if (vm.readyApartments) {
      angular.extend(vm.negotiationCall, { apartments: vm.properties });
    }

    if (vm.negotiationCall.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY) {
      angular.extend(vm.negotiationCall, { documents: vm.uploadedDocumentsList });
    }

    if (vm.isHgf) {
      vm.negotiationCall.participants = vm.negotiation.participants;
      vm.negotiationCall.counterpartOrganizationId = vm.selectedOrganization.id;
    }
    NegotiationCall.save(vm.negotiationCall)
      .$promise.then(function(response) {
        $location.search({});
        $location.path('/negotiation-calls/' + response.id);
        Flash.set('Förhandlingsframställan har skickats till Hyresgästföreningen', 'success');
      })
      .catch(new ErrorHandler('Misslyckades att skapa förhandlingsframställan.'))
      .finally(function() {
        vm.saving = false;
      });
  }

  //Custom pagination controlls for this view.
  vm.goToPrevPage = () => {
    vm.currentPage = vm.currentPage - 1;
    vm.firstRecordInCurrentPage = vm.currentPage * vm.pageLimit + 1;
  };
  vm.goToNextPage = () => {
    vm.currentPage = vm.currentPage + 1;
    vm.firstRecordInCurrentPage = vm.currentPage * vm.pageLimit + 1;
  };
  vm.computeLastRecord = () => {
    if (vm.apartmentsTotalCount - vm.firstRecordInCurrentPage < vm.pageLimit - 1) {
      return vm.apartmentsTotalCount;
    } else {
      return vm.firstRecordInCurrentPage + vm.pageLimit - 1;
    }
  };
  vm.gotoNextStep = () => { vm.gotoNext = true };

  let changeChiefOrganizations = () => {
    let chief = vm.negotiation.participants.find(p => p.isChief);
    vm.chiefParticipantOrganizations = chief ? chief.organizations : null;
    if (vm.chiefParticipantOrganizations && vm.chiefParticipantOrganizations.length === 1) {
      vm.selectedOrganization = vm.chiefParticipantOrganizations[0];
    }else{
      vm.selectedOrganization = null;
    }
  };

  vm.tagChief = (taggedParticipant) => {
    vm.negotiation.participants.forEach(p => p.isChief = (p.id === taggedParticipant.id));
    vm.chiefId = taggedParticipant.id;
    changeChiefOrganizations();
  };

  vm.addParticipant = () => {
    let foundParticipant = vm.negotiation.participants.filter(user => user.id === vm.selectedParticipant.id);

    if (foundParticipant.length === 0) {
      if (vm.negotiation.participants.length === 0) {
        vm.selectedParticipant.isChief = true;
        vm.chiefId = vm.selectedParticipant.id;
      } else {
        vm.selectedParticipant.isChief = false;
      }
      vm.negotiation.participants.push(vm.selectedParticipant);
    }
    vm.selectedParticipant = null;
    changeChiefOrganizations();
  }

  vm.removeParticipant = participant => {
    let isChiefSelected = vm.negotiation.participants.some(participant => participant.isChief === true);
    let index = vm.negotiation.participants.indexOf(participant);
    vm.negotiation.participants.splice(index, 1);

    if (!isChiefSelected) {
      if (vm.negotiation.participants.length > 0) {
        vm.negotiation.participants[0].isChief = true;
        vm.chiefId = vm.negotiation.participants[0].id;
      } else {
        vm.chiefId = null;
      }
    }
    changeChiefOrganizations();
  };

  vm.doUpload = () => {
    let settings = {
      title: 'Ladda upp underlag',
      uploadUrl: '/api/negotiation-calls/new-call-attachments',
      validFileTypes: ValidFileTypes,
      types: [
        AttachmentTypes.NEGOTIATION_ATTACHMENT,
        { id: 9, name: 'Övrigt' },
      ],
      typeTitle: 'Välj typ av underlag',
    };

    HyUtils.showDefaultModal('FileUploadExModalCtrl',
      'components/file-upload-ex-modal/file-upload-ex-modal.html',
      { settings },
      files => {
        vm.attachments.push(...files);
        Flash.set('Lyckades lägga till underlag', 'success');
      }, null, null, { size: 'sm' }
    );
  };

  vm.deleteAttachment = file => {
    vm.attachments = vm.attachments.filter(x => x !== file);
    $scope.$apply();
  };


  vm.showAddNegotiatorModal = () => {
    HyUtils.showDefaultModal('NewUserModalCtrl',
      'resources/user/new/new-user-modal.html',
      { fullMode: false },
      result => {
        let newUser = result.user;
        if (vm.negotiation.participants.length === 0) {
          newUser.isChief = true;
          vm.chiefId = newUser.id;
        } else {
          newUser.isChief = false;
        }
        vm.negotiation.participants.push(newUser);
        changeChiefOrganizations();
      });
  };

  function filterDemand(el, phrase) {
    if (phrase && (!el || !el.demand)) {
      return false;
    }
    if (el.demand.unit === '2') {
      return (el.demand.stringValue).toLowerCase().includes(phrase);
    } else {
      return ('' + el.demand.numericValue).toLowerCase().includes(phrase);
    }
  }

  vm.propertiesFilter = el => {
    if (vm.filterChanging) {
      return el.filtered;
    }

    el.filtered = true;
    if (!vm.filterOwnerEnabled && !vm.filterPropertyEnabled && !vm.filterMunicipalityEnabled && !vm.filterDistrictEnabled
      && !vm.filterStreetsEnabled && !vm.filterDemandEnabled) {
      return true;
    }

    if (vm.filterOwnerEnabled) {
      if (vm.inputTextOwners && vm.inputTextOwners.length > 0) {
        let phrase = vm.inputTextOwners.toLowerCase();
        const containsPhrase = (owners) => {
          return owners
            .filter(owner => owner && owner.trim() !== "")
            .some(owner => owner.toLowerCase().includes(phrase));
        };

        if (el.owners && el.owners.length > 0) {
          el.filtered = containsPhrase(el.owners);
        } else if (el.property && el.property.owners && el.property.owners.length > 0) {
          el.filtered = containsPhrase(el.property.owners);
        } else {
          el.filtered = false;
        }
      }
    }

    if (!el.filtered) {
      return false;
    }

    if (vm.filterPropertyEnabled) {
      if (vm.inputTextProperty && vm.inputTextProperty.length > 0) {
        let phrase = vm.inputTextProperty.toLowerCase();
        if (el.propertyDesignation) {
          el.filtered = el.filtered && (el.propertyDesignation).toLowerCase().includes(phrase);
        } else {
          el.filtered = el.filtered && (el.property.propertyDesignation).toLowerCase().includes(phrase);
        }
      }
    }

    if (!el.filtered) {
      return false;
    }

    if (vm.filterDemandEnabled) {
      if (vm.inputTextDemand && vm.inputTextDemand.length > 0) {
        let phrase = vm.inputTextDemand.toLowerCase();
        el.filtered = el.filtered && filterDemand(el, phrase); //
      }
    }

    if (!el.filtered) {
      return false;
    }

    if (vm.filterMunicipalityEnabled) {
      if (vm.inputTextMunicipality && vm.inputTextMunicipality.length > 0) {
        let phrase = vm.inputTextMunicipality.toLowerCase();
        el.filtered = el.filtered && (el.municipality).toLowerCase().includes(phrase);
      }
    }

    if (!el.filtered) {
      return false;
    }

    if (vm.filterDistrictEnabled) {
      if (vm.inputTextDistrict && vm.inputTextDistrict.length > 0) {
        let phrase = vm.inputTextDistrict.toLowerCase();
        el.filtered = el.filtered && (el.district).toLowerCase().includes(phrase);
      }
    }

    if (!el.filtered) {
      return false;
    }

    if (vm.filterStreetsEnabled) {
      if (vm.inputTextStreets && vm.inputTextStreets.length > 0) {
        let phrase = vm.inputTextStreets.toLowerCase();
        el.filtered = el.filtered && (el.streetAddress).toLowerCase().includes(phrase);
      }
    }

    return el.filtered;
  };

  function filterProperties(field, value) {
    if (field === 0) {
      vm.filterOwnerEnabled = value;
    }

    if (field === 1) {
      vm.filterPropertyEnabled = value;
    }

    if (field === 2) {
      vm.filterMunicipalityEnabled = value;
    }

    if (field === 3) {
      vm.filterDistrictEnabled = value;
    }

    if (field === 4) {
      vm.filterStreetsEnabled = value;
    }

    if (field === 5) {
      vm.filterDemandEnabled = value;
    }

    vm.filterChanging = false;
  }

  function sortProperties(sortColumn, columnTitle) {
    vm.sortPropertyTitle = columnTitle;
    let sortPreviousColumn = vm.sortPropertyColumn;
    vm.sortPropertyColumn = sortColumn;
    if (vm.sortPropertyColumn === sortPreviousColumn) {
      vm.sortPropertyReverse = !vm.sortPropertyReverse;
    } else {
      vm.sortPropertyReverse = false;
    }
  }

  /*
  vm.openRedWarning = () => {
    HyUtils.showDefaultModal('RedLetterWarningModalCtrl',
      'resources/negotiation-call/new/modals/red-letter-warning.html', {});
  };
  */

}

})();