(function () {
'use strict';

angular.module('hyresgastApp').controller('ReportsCtrl', ReportsCtrl);

function ReportsCtrl(Reports,
  ReportParameterTypes, ReportChartTypes,
  HyUtils, StringUtils, Flash,
  $document, $timeout, $scope) {

  const client = 'fp';

  var vm = this;

  vm.paramTypes = ReportParameterTypes;
  vm.chartTypes = ReportChartTypes;

  vm.resultTabs = [
    { title: 'Översikt', key: 'aggregated' },
    { title: 'Detaljerad information', key: 'raw' },
  ];

  vm.paramDictionaries = {};

  vm.busy = true;


  let setDefault = paramType => {
    let typeDictionary = vm.paramDictionaries[paramType];
    let defaultValue = typeDictionary ? typeDictionary[0] : null;
    vm.reports.forEach(report => {
      report.params
        .filter(param => param.type === paramType)
        .forEach(param => param.data = param.dictionary ? param.dictionary[0] : defaultValue);
    });
  };

  /**
   * Sets label for root for tree-like dictionary
   * @param {string} label 
   * @param {string} dictionaryType 
   */
  let setRooted = (label, dictionaryType) => {
    vm.paramDictionaries[dictionaryType] = {
      label, 
      subitems: vm.paramDictionaries[dictionaryType],
    };
  };

  vm.aggregatedTypes = null;

  vm.getAggregatedColumnType = labelIndex => {
    if (!vm.aggregatedTypes && vm.reportResult.chart.labels) {
      vm.aggregatedTypes = vm.reportResult.chart.labels.map(label => {
        let column = vm.reportResult.columns.find(x => x.name === label);
        return (column && column.type) ? column.type : '';
      });
    }
    return vm.aggregatedTypes[labelIndex];
  };

  Reports.query({client}).$promise.then(data => {
    vm.reports = data.reports.sort(StringUtils.stringSort("name"));
    vm.paramDictionaries = data.paramDictionaries;

    //TODO: depending on how new requirements will flow, consider moving to server side,
    //formalizing "default value for parameter"
    setDefault(ReportParameterTypes.YEAR);
    setDefault(ReportParameterTypes.MUNICIPALITY);
    setDefault(ReportParameterTypes.COLLECTION);

    //TODO: maybe move this to server side too
    setRooted('Alla regioner och kommun', ReportParameterTypes.REGION_MUNICIPALITY);

    vm.busy = false;
  });

  vm.selectedReport = null;
  vm.canRunReport = false;

  vm.isParentParamRes = index => {
    let isParent = vm.reportResult && 
    vm.reportResult.selectedSubreport &&
    vm.reportResult &&
    vm.reportResult.selectedSubreport.params.map(x => x.name).includes(vm.reportResult.columns[index].name);
    return isParent;
  };

  let fillSubreportParams = (subreport, resultRow) => {
    subreport.params.forEach(p => {
      if (p.parentRef) {
        p.data = resultRow[p.parentRef];
        p.mandatory = true;
        p.isSet = true;
      }
    });
  };

  let runSubreport = subreport => {
    Reports.run({ report: {
      id: `${vm.selectedReport.id}-sub-${subreport.id}`,
      params: subreport.params.concat(vm.selectedReport.params),
    }}).$promise.then(reportResult => {
        reportResult.rows = unpackRows(reportResult.rowsPacked);
        HyUtils.showDefaultModal('SubreportCtrl',
          'resources/reports/subreport.html', { reportResult },
        null, null, null, 
        { size: 'huge' });
      })
      .catch(() => Flash.set('Misslyckades att kör subreport', 'error'));
  };

  vm.expandResultRow = function (rowIndex) {
    if (!vm.selectedReport.subs || !vm.selectedReport.subs.length) {
      return;
    }
    let firstSubreport = vm.selectedReport.subs[0];
    if (vm.selectedReport.subs.length === 1 && firstSubreport.params.every(p => p.parentRef)) {
      let subreport = firstSubreport;
      fillSubreportParams(subreport, vm.reportResult.rows[rowIndex]);
      runSubreport(subreport);
    } else {
      vm.reportResult.expandedRow = rowIndex;
      vm.subreportChanged(rowIndex);
    }
  };

  vm.reportChanged = function (report) {
    if(report !== vm.selectedReport){
      //clear previous results on change
      vm.reportResult = null;
    }
    vm.selectedReport = report;
  };

  vm.subreportChanged = function (rowIndex) {
    $timeout(() => {
      fillSubreportParams(vm.reportResult.selectedSubreport, vm.reportResult.rows[rowIndex]);
      $scope.$broadcast('checkParams', { rowIndex });
    }, 200);
  };

  vm.runSubreport = () => runSubreport(vm.reportResult.selectedSubreport);

  let unpackRows = rowsPacked => {
    let jsBinary = atob(rowsPacked);
    let binary = Uint8Array.from(jsBinary.split(',').map(c => parseInt(c)));
    let unpacked = pako.ungzip(binary, { to: 'string' });
    return JSON.parse(unpacked);
  };

  vm.runReport = function () {
    vm.busy = true;
    Reports.run({ report: vm.selectedReport })
      .$promise.then(result => {
        vm.aggregatedTypes = null;
        result.fullRows = unpackRows(result.rowsPacked);

        result.rows = result.fullRows.slice(0, 1000);

        vm.reportResult = result;
        let indext = 0;
        vm.reportResult.rows.forEach(r => r.index = indext++);
        vm.reportResult.params = vm.selectedReport.params.deepCopy();

        if (vm.selectedReport.subs) {
          vm.reportResult.selectedSubreport = vm.selectedReport.subs[0];
          vm.reportResult.colCount = result.rows.length ? Object.keys(result.rows[0]).length : -1;
          vm.reportResult.expandedRow = -1;
        }

        if (vm.reportResult.chart) {
          vm.setupChart();
        } else {
          vm.currentResultTab = 'raw';
        }
        vm.busy = false;
    }).catch(() => {
      Flash.set('Misslyckades att kör rapport', 'error');
      vm.busy = false;
    });
  };

  let setChartOptions = (chart, displayLegend) => {
    let options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            maxTicksLimit: Math.max(...(chart.data.map(r => Math.max(...r)))) + 2,
          },
        }]
      },
      legend: {
        display: displayLegend,
        position: 'right',
      }};
    let overrides = {};
    let colors = [
      { borderColor: '#000', backgroundColor: '#000' },
      
      { borderColor: '#00F', backgroundColor: '#008' },
      { borderColor: '#0F0', backgroundColor: '#080' },
      { borderColor: '#0FF', backgroundColor: '#088' },
      { borderColor: '#F00', backgroundColor: '#800' },
      { borderColor: '#F0F', backgroundColor: '#808' },
      { borderColor: '#FF0', backgroundColor: '#880' },
      { borderColor: '#AAA', backgroundColor: '#888' },

      { borderColor: '#00F', backgroundColor: '#88F' },
      { borderColor: '#0F0', backgroundColor: '#8F8' },
      { borderColor: '#0FF', backgroundColor: '#8FF' },
      { borderColor: '#F00', backgroundColor: '#F88' },
      { borderColor: '#F0F', backgroundColor: '#F8F' },
      { borderColor: '#FF0', backgroundColor: '#FF8' },
      { borderColor: '#AAA', backgroundColor: '#FFF' },
    ];

    switch (chart.type) {
      case ReportChartTypes.BAR:
        colors.forEach(c => c.fill = true);
        break;

      case ReportChartTypes.LINE:
        colors.forEach(c => c.fill = false);
        options.bezierCurve = false;
        options.scales.yAxes[0].type ='logarithmic';
        options.scales.yAxes[0].ticks.userCallback = tick => tick.toString();
        options.elements = {
          line: {
            tension: 0
          }
        };
        break;
    }
    chart.options = options;
    chart.colors = colors;
    chart.datasetOverrides = overrides;
  };

  vm.setupChart = function() {
    const tooMuchLabelsTreshold = 8;

    setChartOptions(vm.reportResult.chart, true);
    if (vm.reportResult.chart.subCharts) {
      vm.reportResult.chart.subCharts.forEach((subChart, index) => {
        let last = index === vm.reportResult.chart.subCharts.length - 1;
        setChartOptions(subChart, last);
      });
    }

    if (vm.reportResult.chart.labels.length < tooMuchLabelsTreshold) {
      vm.reportResult.tooMuchLabels = false;
      vm.reportResult.aggregatedColumnWidth = (70 / vm.reportResult.chart.labels.length) + '%';
    } else {
      vm.reportResult.aggregatedColumnWidth = "120px";
      vm.reportResult.tooMuchLabels = true;
      $timeout(prepareMagicScroller, 333);
    }
  };

  vm.drilldown = rowIndex => {

    let fuzzyFilter = (row, param) => Object.values(row).reduce((acc, el) => {
      acc |= param == el;
      return acc;
    }, false);

    let param = vm.reportResult.chart.series[rowIndex];
    
    let rows = vm.reportResult.fullRows.filter(x => fuzzyFilter(x, param));

    let reportResult = {
      rows,
      columns: vm.reportResult.columns,
      title: `nedskärning för ${param}`
    };
    HyUtils.showDefaultModal('SubreportCtrl',
          'resources/reports/subreport.html', { reportResult }, null, null, null, { size: 'huge' });
  };

  function prepareMagicScroller() {
    var tableContainerEl = $document[0].getElementById("magic-table-container");
    var tableHeaderContainerEl = $document[0].getElementById('magic-table-header-container');
    var tableEl = $document[0].getElementById('magic-table');
    var scrollerEl = $document[0].getElementById("magic-scroller");
    var scrollerWorkingBodyEl = $document[0].getElementById('magic-scroller-working-body');

    scrollerWorkingBodyEl.style.width = tableEl.offsetWidth + "px";
    scrollerWorkingBodyEl.style.height = "20px";

    scrollerEl.style.width = tableContainerEl.clientWidth + "px";
    scrollerEl.addEventListener("scroll", function() {
      tableHeaderContainerEl.scrollLeft = scrollerEl.scrollLeft;
      tableContainerEl.scrollLeft = scrollerEl.scrollLeft;
    });
  }

  vm.export = function (format) {
    let chart = $document[0].getElementById('myChart');
    let image = chart ? chart.toDataURL('image/png') : null;

    let isPdf = format === 'pdf';

    let exportObject = {
      title: vm.reportResult.title,
      paramSummary: $document[0].getElementById('paramSummary').innerText,
      data: vm.reportResult.rowsPacked,
      chartData: vm.reportResult.chart,
      columns: vm.reportResult.columns,
      image,
      report: vm.selectedReport,
    };

    vm.busy = true;
    let filename = (vm.reportResult.title).replace(/:| /g, '') + '.' + format;

    if (isPdf) {
      Reports.savePdf(exportObject)
      .$promise.then(result => {
        vm.busy = false;
        HyUtils.spitOutAttachment(result.buffer.data, filename, 'application/pdf');
      }).catch(() => Flash.set('Misslyckades att spara', 'error'));      
    } else {
      $timeout(() => {
        let columnNames = exportObject.columns.map(c => c.name);
        let sourceKeys = Object.keys(vm.reportResult.fullRows[0]);
        let sheetData = vm.reportResult.fullRows.map(source => {
          let result = {};
          columnNames.forEach((c, i) => {
            let value = source[sourceKeys[i]];
            if (value instanceof Array) {
              value = value[0];
            }
            result[c] = value;
          });
          return result;
        });

        let workbook = XLSX.utils.book_new();
        let sheet = XLSX.utils.json_to_sheet(sheetData, { header: columnNames });
        XLSX.utils.book_append_sheet(workbook, sheet,'DATA');
        let output = XLSX.write(workbook, {type: 'buffer'});

        HyUtils.spitOutAttachment(output, filename, 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet');

        vm.busy = false;
      }, 200);
    }
  };
}
})();