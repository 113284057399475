(function () {
'use strict';

angular.module('hyresgastApp').controller('ListNegotiationOfficesCtrl', ListNegotiationOfficesCtrl);

function ListNegotiationOfficesCtrl(
  $scope,
  $location,
  $routeParams,
  Flash,
  Auth,
  User,
  Parties,
  Negotiation,
  NegotiationStates,
  NegotiationTypes,
  ErrorHandler
) {
  var vm = this;
  vm.negotiations = [];
  vm.getMyListOfNegotiationsByOffice = getMyListOfNegotiationsByOffice;
  vm.gotoShow = gotoShow;
  vm.statusChoices = angular.extend({
    ALL: ''
  }, NegotiationStates);
  vm.typeChoices = angular.extend({}, NegotiationTypes);
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'callForNegotiation.negotiationDate'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default
  vm.user = {};
  vm.listColumns = [{
    title: 'Id',
    column: 'caseNumber'
  },
  {
    title: 'Namn',
    column: 'name'
  },
  {
    title: 'Organisation',
    column: 'counterpart.name'
  },
  {
    title: 'Kontor',
    column: 'office.name'
  },
  {
    title: 'Status',
    column: 'state'
  },
  {
    title: 'Begärt ändringsdatum',
    column: 'rentEffectiveFrom'
  },
  {
    title: 'Önskat datum för förhandling',
    column: 'callForNegotiation.negotiationDate'
  }
  ];

  $scope.$on('$routeUpdate', function () {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getMyListOfNegotiationsByOffice();
    }
  });

  Auth.heartbeat().then(function () {
    getUserOffices();
  });

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id);
  }

  function getUserOffices() {
    return User.get({
      id: Auth.currentUser().id
    })
      .$promise.then(function (response) {
        vm.user = response;
        // Convert response.offices object to an array for easily sorting and using length property
        var offices = [];
        for (var office in response.offices) {
          offices.push(response.offices[office]);
        }
        vm.user.offices = offices;
      })
      .catch(ErrorHandler('Misslyckades att hämta data.'))
      .finally(() => { vm.ready = true });
  }

  let getParams = (all, includeProperties) => ({
    limit: all ? 1000 : vm.pageLimit,
    offset: all ? 0 : vm.pageOffset,
    order: vm.sortColumn,
    direction: vm.sortDirection,
    myOffices: 'true',
    'filters[caseNumber]': vm.inputTextId,
    'filters[state]': vm.statusId,
    'filters[type]': vm.typeId,
    'search[name]': vm.inputTextName,
    'search[counterpart.name]': vm.inputTextOrganisation,
    'search[office.name]': vm.inputTextOffice,
    includeProperties,
  });

  vm.getAllNegotiations = (cb, includeProperties) => {
    Negotiation.query(getParams(true, includeProperties), {})
      .$promise.then(function (data) { cb(data) });
  };
  function getMyListOfNegotiationsByOffice(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    return Negotiation.query(getParams(), {})
      .$promise.then(function (data) {
        vm.negotiations = data.rows;
        vm.negotiationsCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function () {
        vm.ready = true;
      });
  }

  //start
  getMyListOfNegotiationsByOffice();
}
})();