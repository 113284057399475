(function () {
'use strict';

angular.module('hyresgastApp').controller('ListNegotiationResponsiblesCtrl', ListNegotiationResponsiblesCtrl);

function ListNegotiationResponsiblesCtrl(
  $scope,
  $location,
  $routeParams,
  Negotiation,
  NegotiationStates,
  NegotiationTypes,
  Limits,
  Flash,
  ErrorHandler
) {
  var vm = this;
  vm.negotiations = [];
  vm.getListByChief = getListByChief;
  vm.gotoShow = gotoShow;
  vm.statusChoices = angular.extend({
    ALL: ''
  }, NegotiationStates);
  vm.typeChoices = angular.extend({}, NegotiationTypes);
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'callForNegotiation.negotiationDate'; //default
  vm.sortDirection = $location.search().direction || 'ASC'; //default
  vm.listColumns = [{
    title: 'Id',
    column: 'caseNumber'
  },
  {
    title: 'Namn',
    column: 'name'
  },
  {
    title: 'Organisation',
    column: 'counterpart.name'
  },
  {
    title: 'Kontor',
    column: 'office.name'
  },
  {
    title: 'Förhandlare',
    column: 'participants.firstname'
  },
  {
    title: 'Status',
    column: 'state'
  },
  {
    title: 'Önskat datum för förhandling',
    column: 'callForNegotiation.negotiationDate'
  }
  ];

  $scope.$on('$routeUpdate', function () {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getListByChief();
    }
  });

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id);
  }

  let getParams = (all, includeProperties) => ({
    limit: all ? Limits.InMemoryMiddleLimit : vm.pageLimit,
    offset: all ? 0 : vm.pageOffset,
    order: vm.sortColumn,
    direction: vm.sortDirection,
    'filters[caseNumber]': vm.inputTextId,
    'filters[state]': vm.statusId,
    'filters[type]': vm.typeId,
    'search[name]': vm.inputTextName,
    'search[counterpart.name]': vm.inputTextOrganisation,
    'search[office.name]': vm.inputTextOffice,
    'search[participants.firstname,participants.lastname]': vm.inputTextNegParticipants,
    includeProperties,
  });

  vm.getAllNegotiations = (cb, includeProperties) => {
    Negotiation.queryByChief(getParams(true, includeProperties), {})
      .$promise.then(function (data) { cb(data) });
  };
  function getListByChief(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    return Negotiation.queryByChief(getParams(), {})
      .$promise.then(function (data) {
        vm.negotiations = data.rows;
        vm.negotiationsCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function () {
        vm.ready = true;
      });
  }

  getListByChief();
}
})();