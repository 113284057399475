(function () {
'use strict';

angular.module('hyresgastApp').controller('ListNegotiationCtrl', ListNegotiationCtrl);

function ListNegotiationCtrl($scope, $location, $routeParams, $route, $filter,
  Negotiation, Seenby,
  HyUtils, SubPage, Auth, Flash, ErrorHandler,
  Parties, ObjectTypes, NegotiationStates) {
  var vm = this;
  vm.Auth = Auth;

  Auth.heartbeat().then(function() {
    vm.headerButtons = [
      {
        title: 'Skapa en framställan',
        url: '/negotiation-calls/new',
        isVisible: () => (Auth && Auth.canDo('createCall')),
        icon: 'call'
      },
    ];
    vm.isHgfUser = Auth.currentUser().party === Parties.HGF;

    getSeenbyCounters();
  });

  let setMarkAsRead = () => {
    if (vm.seenbyCounters) {
      const pages = {
        'my-negotiations': 'c_mine',
        'my-office': 'c_myoffices',
        'all': 'c_all',
      };
      vm.showMarkAsRead = vm.currentSubpage === 'my-negotiations' &&
        vm.seenbyCounters[pages[vm.currentSubpage]] > 0;
    }
  }

  getCurrentSubpage();

  let setupTabs = () => {
    vm.tablist = [
      { title: 'Mina', path: '/negotiations',
        number: vm.seenbyCounters.c_mine, numberTooltip: 'Antal inkomna förhandlingar som inte öppnats' },
      { title: 'På mina kontor', path: '/negotiations?view=my-office' },
      { title: 'Per förhandlare', path: '/negotiations?view=responsibles' },
      { title: 'Alla', path: '/negotiations?view=all' },
    ];
    if (!vm.isHgfUser) {
      vm.tablist.splice(1, 2);
    }
  };

  let getSeenbyCounters = () => {
    Negotiation.getSeenbyCounters({})
      .$promise.then(response => {
        vm.seenbyCounters = response;
        setupTabs();
        setMarkAsRead();
      });
  }

  $scope.$on('$routeUpdate', function() {
    // avoid unecessary calling of getCurrentSubpage(); when sorting
    if (!$location.search().order) {
      // reset offset when sorting
      getCurrentSubpage();
    }
  });

  /////////

  function getCurrentSubpage() {
    var defaultSubPage = 'my-negotiations';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = ['my-office', 'responsibles', 'all'];
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
    setMarkAsRead();
  }

  const vmList = {
    'my-negotiations': 'myNegVM',
    'my-office': 'officesVM',
    'responsibles': 'responsiblesVM',
    'all': 'allVM'
  };
  vm.changeNegotiator = function () {
    let child = HyUtils.bruteSearch($scope, vmList[vm.currentSubpage]);
    if (child) {
      child.getAllNegotiations(negotiations => {
        HyUtils.showDefaultModal('ChangeNegotiatorModalCtrl',
          'resources/negotiation/list/change-negotiator/change-negotiator-modal.html', {
            negotiations,
          }, result => { Negotiation.changeNegotiators(result) });
      });
    }
  };
  vm.markAsRead = function () {
    let child = HyUtils.bruteSearch($scope, vmList[vm.currentSubpage]);
    if (child) {
      child.getAllNegotiations(negotiations => {
        let negotiationsToMark = negotiations.rows
          .filter(x => !x.seenByMe)
          .map(x => x.id);
        Seenby.markAsRead({
          objectType: ObjectTypes.NEGOTIATION.code,
          objectIds: negotiationsToMark,
        // eslint-disable-next-line no-unused-vars
        }).$promise.then(response => {
          Flash.set('Markerad som läst', 'success');
          $route.reload();
        }).catch(ErrorHandler('Misslyckades att markerad som läst'));
      });
    }
  };

  vm.beginExport = function () {
    vm.exportStatuses = Object.values(NegotiationStates).map(code => ({ code, isSet: true }));
    vm.showExportPopup = true;
    vm.exportBusy = false;
  };
  vm.export = function () {
    vm.exportBusy = true;
    const propertyText = property => `${property.propertyDesignation}, ${property.municipality}`;
    const fullName = user => `${user.firstname} ${user.lastname}`;
    let child = HyUtils.bruteSearch($scope, vmList[vm.currentSubpage]);
    if (child) {
      child.getAllNegotiations(negotiations => {
        let selectedStatuses = vm.exportStatuses.filter(x => x.isSet).map(x => x.code);
        negotiations.rows = negotiations.rows.filter(n => selectedStatuses.includes(n.state));
        let sheetData = [];
        negotiations.rows.forEach(x => {
          let excelRow = {
            'Id': x.caseNumber,
            'Namn': x.name,
            'Förhandlingstyp': $filter('hyNegotiationTypes')(x.type),
            'Organisation': x.counterpart.name,
            'Förhandlare': fullName(x.hgfNegotiator ? x.hgfNegotiator : x.participants),
            'Begärt ändringsdatum': $filter('date')(x.rentEffectiveFrom, 'yyyy-MM-dd'),
            'Önskat datum för förhandling': x.callForNegotiation.negotiationDate ? $filter('date')(x.callForNegotiation.negotiationDate, 'yyyy-MM-dd') : 'Ej angivet',
            'Slutdatum': x.finishDate ? $filter('date')(x.finishDate, 'yyyy-MM-dd') : 'Ej angivet',
            'Fastighet': x.properties && x.properties.length ? (propertyText(x.properties[0]) + (x.properties[0].used ? ' (avslutad)': ' ')) : '',
            'Ägare': x.properties && x.properties.length && x.properties[0].owners.length
              ? x.properties[0].owners.map(o => o.name).join(', ')
              : '',
            'Status': $filter('hyNegotiationStates')(x.state),
            'Kommentar': x.comment ? x.comment : '',
          };
          if (x.extNegotiator) {
            excelRow['Ansvarig motpart'] = fullName(x.extNegotiator);
          }
          sheetData.push(excelRow);
          if (x.properties && x.properties.length > 1) {
            x.properties.slice(1).forEach(p => {
              sheetData.push({
                'Fastighet': (propertyText(p) + (p.used ? ' (avslutad)': ' ')),
                'Ägare': p.owners.length
                  ? p.owners.map(o => o.name).join(', ')
                  : '',
              });
            });
          }
        });

        let columnNames = Object.keys(sheetData[0]);
        // eslint-disable-next-line no-undef
        let xlsx = XLSX;
        let workbook = xlsx.utils.book_new();
        let sheet = xlsx.utils.json_to_sheet(sheetData, { header: columnNames });
        const colLengths = Object.keys(sheetData[0]).map(k => k.toString().length);
        for (const d of sheetData) {
          Object.values(d).forEach((element, index) => {
            colLengths[index] = Math.max(element.toString().length, colLengths[index]);
            colLengths[index] = Math.min(colLengths[index], 100);
          });
        }
        sheet["!cols"] = colLengths.map((l) => ({ wch: l }));
        xlsx.utils.book_append_sheet(workbook, sheet, 'DATA');
        let output = xlsx.write(workbook, {type: 'buffer'});

        HyUtils.spitOutAttachment(output, 'Förhandlingarlista export.xlsx', 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet');
        vm.showExportPopup = false;

      }, true);
    }
  };

  vm.editComment = negotiation => {
    HyUtils.showDefaultModal('EditCommentModalCtrl',
      'resources/agreement/rentlist/edit/edit-comment-modal.html',
      { comment: negotiation.comment, multipleComments: '' },
      result => {
        if (result.delete || result.comment) {
          negotiation.comment = result.delete ? '' : result.comment;
          Negotiation.saveComments([{
            negotiationId: negotiation.id,
            comment: negotiation.comment,
          }]);
        }
      }, null, null,
      { size: 'md' });
  };
}

})();