(function () {
'use strict';

const cmp = (label, filter) => label.toLowerCase().includes(filter.toLowerCase());

const item_cmp = (item, filter) => cmp(item.label, filter) 
    ? true
    : item.subitems && item.subitems.some(i => item_cmp(i, filter));

const setTickedTree = function (subitems, ticked, newValue, filter) {
  subitems.forEach(element => {
    if ( !(filter && filter.length) || cmp(element.label, filter) ) {
        ticked[element.data] = newValue;
    }
    
    if (element.subitems) {
      setTickedTree(element.subitems, ticked, newValue, filter);
    }
  });
}

const flatTree = (obj, result = [], level = 0) => {
  result.push({ 
    data: obj.data,
    label: obj.label,
    level,
  });
  if (obj.subitems) {
   	obj.subitems.forEach(o => flatTree(o, result, level+1));
  }
  return result;
}

angular.module('treeNode', [])
  .directive('treeNode', () => {
    return {
      scope: {
        label: '@',
        data: '=',
        subitems: '=',
        checkboxes: '<',
        selected: '=',
        model: '=',
        ticked: '=',
        viewbag: '=',
      },
      restrict: 'E',
      templateUrl: 'components/treeview/tree-node.html',
      link: function (scope) {
        scope.filterMatch = function () {
          return item_cmp(scope, scope.viewbag.filter);
        }
        scope.selectMe = function () {
          scope.selected({
            newValue: {
              label: scope.label,
              data: scope.data,
            }
          });
        }
        scope.tickMe = function () {
          setTimeout(() => {
            if (scope.subitems) {
              setTickedTree(scope.subitems, scope.ticked, scope.ticked[scope.data], scope.viewbag.filter);
            }
            scope.selected();
          }, 100);
        }
      }
    }
  });

angular.module('treeView', [])
  .directive('treeView', () => {
    return {
      scope: {
        mode: '@',
        root: '=',
        checkboxes: '<',
        collapseOnSelect: '<',
        showSelectionOnCollapse: '<',
        skipRoot: '<',
        filtering: '<',
        filterPrompt: '@',

        selected: '&',

        model: '=',
        tickedNodes: '=',
      },
      restrict: 'E',
      templateUrl: 'components/treeview/tree-view.html',
      link: function (scope) {

        scope.viewbag = { filter: '' };

        scope.$watch('mode', newMode => {
          switch (newMode) {
            case 'field':            
            scope.showSelectionOnCollapse = true;
            scope.collapseOnSelect = true;
              break;
            case 'list': 
            scope.skipRoot = true;
              break;
          }
          //scope.$apply();
        });
        scope.$watch('root', newRoot => {
          scope.ticked = {};
        });

        scope.setEvents = () => {
          let togglers = document.getElementsByClassName("treeview-caret");
          let i;

          for (i = 0; i < togglers.length; i++) {
            togglers[i].addEventListener("click", function () {
              this.parentElement.querySelector(".treeview-nested").classList.toggle("treeview-active");
              this.classList.toggle("treeview-caret-down");
            });
          }
        };
        scope.reset = () => {
          scope.model = undefined;
          setTimeout(() => { scope.setEvents() }, 500);
        };
        scope.selected = eventData => {
          if (eventData && eventData.newValue) {
            scope.model = eventData.newValue;

            if (scope.collapseOnSelect) {
              let togglers = document.getElementsByClassName("treeview-caret-down");
              let i;
              for (i = 0; i < togglers.length; i++) {
                let el = togglers[i];
                el.parentElement.querySelector('.treeview-nested').classList.toggle("treeview-active");
                el.classList.toggle("treeview-caret-down");
              }
            }
          } else {
            if (scope.checkboxes) {
              let flat = flatTree(scope.root);
              scope.tickedNodes = flat.filter(x => scope.ticked[x.data] === true);
              scope.$apply();
            }
          }
        };
        setTimeout(() => { scope.setEvents() }, 500);
      }
    }
  });
})();