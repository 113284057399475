(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationAgreementCtrl', ShowNegotiationAgreementCtrl);

function ShowNegotiationAgreementCtrl(
  $scope,
  $location,
  $routeParams,
  $uibModal,
  Negotiation,
  Agreement,
  AgreementTypes,
  Blocking,
  Auth,
  Parties,
  AgreementBlockedByType,
  Flash,
  ErrorHandler,
  NegotiationTypes,
  HyUtils) {

  //this controller was expected to only fetch agreements, however now we fetch blocking and apartment histrory also
  var vm = this;

  vm.hyUtils = HyUtils;

  vm.parentCtrlVM = $scope.$parent.vm;
  vm.AgreementBlockedByType = AgreementBlockedByType;
  vm.AgreementTypes = AgreementTypes;
  vm.gotoShowAgreement = gotoShowAgreement;
  vm.gotoShowBlocking = gotoShowBlocking;
  vm.resume = resume;
  vm.createAgreement = createAgreement;
  vm.acceptBlockedAgreement = acceptBlockedAgreement;
  vm.blockAgreement = blockAgreement;

  Auth.heartbeat().then(function() {
    vm.currentUserParty = Auth.currentUser().party;
    if (vm.currentUserParty === Parties.HGF) {
      vm.isHgfUser = true;
    }
  });

  getAgreements();
  getBlockings();

  /////////

  function getAgreements() {
    return Agreement.query({ negotiationId: $routeParams.id })
      .$promise.then(function(response) {
        vm.agreements = response;
      })
      .catch(new ErrorHandler('Misslyckades att hämta överenskommelser.'))
      .finally(function() {
        vm.readyAgreements = true;
      });
  }

  function getBlockings() {
    return Blocking.query({ negotiationId: $routeParams.id })
      .$promise.then(function(response) {
        vm.blockings = response;
      })
      .catch(new ErrorHandler('Misslyckades att hämta strandningar.'))
      .finally(function() {
        vm.readyBlockings = true;
      });
  }

  function refreshNegotiation() {
    return Negotiation.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.parentCtrlVM.negotiation = response;
      })
      .catch(new ErrorHandler('Misslyckades att hämta förhandling.'));
  }

  function resume($event, blocking) {
    // dont want to propagate to parent ng-click which would redirect
    // user to blocking show page
    $event.stopPropagation();

    return Blocking.resume({
      negotiationId: $routeParams.id,
      id: blocking.id
    })
      .$promise.then(function() {
        // Remove item from blockings list
        vm.blockings.splice(blocking, 1);
        refreshNegotiation();
        Flash.set('Lyckades återuppta strandning', 'success');
      })
      .catch(new ErrorHandler('Misslyckades att återuppta strandning.'));
  }

  function gotoShowAgreement(id) {
    $location.search({});
    $location.path('/negotiations/' + $routeParams.id + '/agreements/' + id);
  }

  function gotoShowBlocking(id) {
    $location.search({});
    $location.path('/negotiations/' + $routeParams.id + '/blockings/' + id);
  }

  function createAgreement () {
    if (vm.parentCtrlVM.negotiation.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY ||
        vm.parentCtrlVM.negotiation.type === NegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT) {
      vm.hyUtils.askQuestion('Gäller presumptionshyra för beståndet?', null, 2)
        .then(pRes => {
          vm.hyUtils.askQuestion('Söks investeringsstöd?', null, 2)
            .then(iRes => {
              _createAgreement(pRes === 'Ja', iRes === 'Ja');
            });
        });
    } else {
      _createAgreement();
    }
  }

  function _createAgreement (isPresumtiveRent, isInvestmentProperty) {
    vm.savingAgreement = true;
    var agreement = {
      negotiationId: $routeParams.id,
      name: '', // backend requires it
    };

    var conditionTags = {};
    if (isPresumtiveRent) {
      conditionTags.isPresumtiveRent = isPresumtiveRent;
    }
    if (isInvestmentProperty) {
      conditionTags.isInvestmentProperty = isInvestmentProperty;
    }
    agreement.conditionTags = JSON.stringify(conditionTags);

    Agreement.save(agreement)
      .$promise.then(
        function(response) {
          Flash.set('Överenskommelse har skapats', 'success');
          $location.search({});
          $location.path('/negotiations/' + $routeParams.id + '/agreements/' + response.agreement.id)
        })
      .catch(function() {
        Flash.set('Misslyckades att skapa överenskommelse', 'error')
      })
      .finally(function() {
        vm.savingAgreement = false;
      });
  }

  function acceptBlockedAgreement(blocking) {
    var settings = {
      subtitle: 'Är du säker på att du vill gå vidare?',
      showDropdown: false
    };
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'resources/negotiation/show/agreements/modal/confirmationBlockedModal.html',
      controller: 'ConfirmationBlockedModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        settings: function() {
          return settings;
        }
      }
    });
    modalInstance.result.then(function() {
      if (vm.isHgfUser) {
        blocking.agreedByAssociation = true;
      } else {
        blocking.agreedByOwner = true;
      }
      Blocking.save(blocking)
        .$promise.then(function() {
          if (blocking.agreedByAssociation && blocking.agreedByOwner) {
            blocking.isAgreed = true;
          }
        })
        .catch(new ErrorHandler('Misslyckades att spara strandning.'));
    });
  }

  function blockAgreement() {
    var settings = {
      subtitle: 'Är du säker på att du vill stranda lägenheter?',
      showDropdown: true
    };

    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'resources/negotiation/show/agreements/modal/confirmationBlockedModal.html',
      controller: 'ConfirmationBlockedModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        settings: function() {
          return settings;
        }
      }
    });
    modalInstance.result.then(function(blockedBy) {
      $location.path('/negotiations/' + $routeParams.id + '/blockings/new').search({ blockedBy: blockedBy });
    });
  }
}

})();