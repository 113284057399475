(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('NegotiationTableNegotiatorFinishedListCtrl', NegotiationTableNegotiatorFinishedListCtrl);

function NegotiationTableNegotiatorFinishedListCtrl(
  $scope, $location, $routeParams,
  FeatureConfiguration, NegotiationStates, NegotiationTypes, SubPage, User,
  ErrorHandler
) {
  var vm = this;
  vm.getFinishedNegotiationsWithMe = getFinishedNegotiationsWithMe;
  vm.statusChoices = angular.extend({ ALL: '' }, NegotiationStates);
  vm.negotiationTypes = angular.extend({ ALL: '' }, NegotiationTypes);
  vm.FeatureConfiguration = FeatureConfiguration;
  vm.gotoShow = gotoShow;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'caseNumber'; // default
  vm.sortDirection = $location.search().direction || 'DESC'; // default
  vm.listColumns = [
    { title: 'Id', column: 'caseNumber' },
    { title: 'Namn', column: 'name' },
    { title: 'Typ', column: 'counterpart.name' },
    { title: 'Dagar kvar', column: 'rentEffectiveFrom' },
    { title: 'Datum för framställan', column: 'updatedAt' },
    { title: 'Status', column: 'state' },
    { title: 'Begärt ändringsdatum', column: 'updatedAt' }
  ];
  if (vm.FeatureConfiguration.ENABLE_MEETINGS) {
    vm.listColumns.splice(4, 0, { title: 'Nästa möte', column: 'updatedAt' });
  }

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getFinishedNegotiationsWithMe();
    }
  });

  /////////
  getCurrentSubpage();
  getFinishedNegotiationsWithMe();

  function gotoShow(id) {
    $location.search({});
    $location.path('/negotiations/' + id);
  }

  function getFinishedNegotiationsWithMe(filterMode) {
    // include pageoffset as parameter?
    vm.readyFinished = false;
    if (filterMode) {
      vm.pageOffset = 0;
    }

    return User.queryNegotiationsWithMe({
      partnerId: $routeParams.partnerId,
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'filters[state]': [NegotiationStates.FINISHED],
      'filters[caseNumber]': vm.inputTextId,
      'filters[counterpartOrganizationId]': $routeParams.organizationId,
      'search[negotiationName]': vm.inputTextName,
      'filters[type]': vm.typeId
    })
      .$promise.then(function(data) {
        vm.myFinishedNegotiations = data.rows;
        vm.totalCount = data.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lista.'))
      .finally(function() {
        vm.readyFinished = true;
      });
  }

  function getCurrentSubpage() {
    var defaultSubPage = 'list';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = ['properties', 'finished'];
    if (vm.FeatureConfiguration.ENABLE_MEETINGS) {
      whiteList.push('meetings');
    }
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }
}

})();