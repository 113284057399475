(function () {
'use strict';

var app = angular.module('hyresgastApp');


const partiesHGF = 20;
const partiesDELEGATE = 10;
const partiesEXTERNAL = 0;

app.value('RoleLookup', [
  {
    key: 'SPECTATOR',
    value: 50,
  },
  {
    key: 'USER',
    value: 100
  },
  {
    key: 'ADMIN',
    value: 200
  }
]);

app.value('PartyLookup', [
  {
    key: 'EXTERNAL',
    value: 0
  },
  {
    key: 'DELEGATE',
    value: 10
  },
  {
    key: 'HGF',
    value: 20
  }
]);

app.value('Roles', {
  NONE: 0,
  SPECTATOR: 50,
  USER: 100,
  ADMIN: 200
});

app.value('Parties', {
  EXTERNAL: 0,
  DELEGATE: 10,
  HGF: 20
});

app.value('NegotiationCallStates', {
  WAITING_HGF: 51, // created and sent, waiting for Hgf action
  WAITING_EXT: 101, // created and sent, inspected by Hgf and found lacking, waiting for external action
  PARTIALLY_ASSIGNED: 201, // some real estate covered has been assigned to a negotiation, but not all
  ASSIGNED: 301 // all real estate has been assigned to a negotiation, no more action needed
});

app.value('NegotiationStates', {
  NEW: 0, //Ny
  IN_PREPARATION: 22, // 'bereds'
  SETTLEMENT: 44, // uppgörelse
  IN_PROGRESS: 72, // pågår
  FINISHED_OUTSIDE_PORTAL: 592, // Felaktigt avslutad förhandling
  BLOCKED: 922, // strandad
  PARTIALLY_BLOCKED: 972, // delvis strandad
  FINISHED: 5252 // avslutad
});

app.value('AgreementTypes', {
  AGREEMENT: 0,
  BLOCKING: 1,
  AGREEMENT_WITHOUT_RENTLIST: 2,
});

app.constant('FreeAgreementTypes', {
  FÖRHANDLINGSORDNING:    { code: 9, name: 'Förhandlingsordning' },
  TILLVALSAVTAL:          { code: 10, name: 'Tillvalsavtal' },
  RAMAVTAL:               { code: 11, name: 'Ramavtal' },
  HYRESSÄTTNINGSAVGIFT:   { code: 12, name: 'Hyressättningsavgift' },
  UNTERHÅLLSAVTAL:        { code: 13, name: 'Underhållsavtal' },
  IMD:                    { code: 14, name: 'IMD individuell mätning och debitering' },
  FÖRHANDLINGSPROTOKOLL:  { code: 15, name: 'Förhandlingsprotokoll' }
});

app.value('NegotiationProtocolStates', {
  DRAFT: 0,
  SAVED: 14,
  CONFIRMED: 34,
  SIGNED: 64 // signerad
});

app.value('NegotiationCallSuspectedErrors', {
  MISSING: 'missingMedWindProperty',
  NO_OWNER: 'noMedWindOwner',
  NO_NEGOTIATION: 'noMedWindNegotiationAgreement',
  ALREADY_CALLED: 'alreadyCalled',
  IN_NEGOTIATION: 'alreadyInNegotiation'
});

app.constant('NegotiationLandlordSides', {
  LANDLORD: 0,
  LANDLORD_AND_ORGANIZATION: 1
});

app.value('AgreementBlockedByType', {
  PRIVATE: 0, // Hyresnämnden
  PUBLIC: 1, // HMK
  NONE: 2 // Ingen
});

app.value('Regions', {
  NORRLAND: 1, // Norrland
  AROS_GEVLE: 2, // Aros - Gävle
  STOCKHOLM: 3, // Stockholm
  MIDDLE: 4, // Mitt
  WEST_SWEDEN: 6, // Västra Sverige
  BESK: 7, // Bäsk
  SOUTH_EAST: 8, // Sydost
  NORTH_SKONE: 9, // Norra Skåne
  SOUTH_SKONE: 10 // Södra Skåne
});

app.value('NegotiationTypes', {
  ANNUAL_PROPERTY: 0, // Årlig hyresförhandling av fastigheter
  STANDARD_PROPERTY: 2, // Standardförändring av fastigheter
  STANDARD_APARTMENT: 3, // Standardförändring av lägenheter
  REBUILD_PROPERTY: 4, // Hela fastigheten ombyggnation/renovering
  REBUILD_APARTMENT: 5, // Ombyggnation/renovering av lägenheter
  NEWLY_CONSTRUCTED_PROPERTY: 6, // Nyproduktion av fastigheter
  NEWLY_CONSTRUCTED_APARTMENT: 7, // Nyproduktion av lägenheter i befintlig fastighet
  UTILITY_VALUE_PROPERTY: 8, // Bruksvärdesförhandling av fastigheter
  UTILITY_VALUE_APARTMENT: 9, // Bruksvärdesförhandling av lägenheter
  
  REBUILD_PROPERTY_2: 1001,   //Enskild lägenhet ombyggnation/renovering
});

app.value('OrderedNegotiationTypes', {
  ANNUAL_PROPERTY: 0, // Årlig hyresförhandling av fastigheter
  STANDARD_PROPERTY: 2, // Standardförändring av fastigheter
  REBUILD_PROPERTY: 4, // Hela fastigheten ombyggnation/renovering
  REBUILD_PROPERTY_2: 1001,   //Enskild lägenhet ombyggnation/renovering
  NEWLY_CONSTRUCTED_APARTMENT: 7, // Nyproduktion av lägenheter i befintlig fastighet
  NEWLY_CONSTRUCTED_PROPERTY: 6, // Nyproduktion av fastigheter
  UTILITY_VALUE_PROPERTY: 8, // Bruksvärdesförhandling av fastigheter
});

app.value('PropertyNegotiationTypes', {
  ANNUAL_PROPERTY: 0, // Årlig hyresförhandling av fastigheter
  STANDARD_PROPERTY: 2, // Standardförändring av fastigheter
  REBUILD_PROPERTY: 4, // Ombyggnation/renovering av fastigheter
  REBUILD_PROPERTY_2: 1001, // Enskild lägenhet obyggnation/renovering
  NEWLY_CONSTRUCTED_PROPERTY: 6, // Nyproduktion av fastigheter
  UTILITY_VALUE_PROPERTY: 8, // Bruksvärdesförhandling av fastigheter
});

app.value('ApartmentNegotiationTypes', {
  STANDARD_APARTMENT: 3, // Standardförändring av lägenheter
  REBUILD_APARTMENT: 5, // Ombyggnation/renovering av lägenheter
  NEWLY_CONSTRUCTED_APARTMENT: 7, // Nyproduktion av lägenheter i befintlig fastighet
  UTILITY_VALUE_APARTMENT: 9 // Bruksvärdesförhandling av lägenheter
});

app.value('ValidFileTypes', 'msg odt txt pdf ppt pptx doc docx xml csv jpg gif png tif tiff xls xlsx');

// 'Parties' has been explicitely defined as a constant because
// app.value('Parties') cannot be injected in $routeProvider
app.constant('Parties', {
  EXTERNAL: 0,
  DELEGATE: 10,
  HGF: 20
});

app.constant('KitchenTypes', ['ingen', 'kök', 'kokskåp', 'kokvrå', 'gemensamt', 'mkök']);

app.constant('BuildingTypes', ['övrig', 'radhus', 'villa']);

app.constant('NegotiationRoles', {
  HGF_ASSOC:                { code: 0, party: partiesHGF, isChief: false, name: 'Hyresgästföreningen' },
  HGF_ASSOC_CHIEF:          { code: 92, party: partiesHGF, isChief: true, name: 'Hyresgästföreningen, Ansvarig förhandlare' },
  HGF_ELECTED:              { code: 30, party: partiesHGF, isChief: false, name: 'Förtroendevald HGF' },
  HGF_ELECTED_CHIEF:        { code: 122, party: partiesHGF, isChief: true, name: 'Förtroendevald HGF, Ansvarig förhandlare' },

  FA_POWNER:                { code: 2, party: partiesEXTERNAL, isChief: false, name: 'Hyresvärd' },
  FA_POWNER_CHIEF:          { code: 172, party: partiesEXTERNAL, isChief: true, name: 'Hyresvärd, Ansvarig förhandlare' },

  OMBUD_FA_POWNER:          { code: 42, party: partiesEXTERNAL, isChief: false, name: 'Hyresvärd ombud' },
  OMBUD_FA_POWNER_CHIEF:    { code: 212, party: partiesEXTERNAL, isChief: true, name: 'Hyresvärd ombud, Ansvarig förhandlare' },

  PARTICIPANT_DELEGATION:   { code: 3, party: partiesDELEGATE, name: 'Delegat' },
  PARTICIPANT_SUPERDELEGAT: { code: 5, party: partiesDELEGATE, name: 'Super delegat' }
});

app.constant('ApartmentStatusTypes', {
  PRESUMTIVE_RENT:      { code: 1, name: 'P', longName: 'Presumptionshyra', colouredName: '<b>P</b>' },
  STUCK:                { code: 2, name: 'S', longName: 'Strandad', colouredName: '<b class="red-text">S</b>' },
  WITHOUT_NEGOTIATION:  { code: 4, name: 'UF', longName: 'Utan förhandlingsklausul' },
  TRIBE_DECISION:       { code: 8, name: 'HN', longName: 'Beslutade av hyresnämnd' },
  IN_NEGOTIATION:       { code: 16, name: 'F', longName: 'Förhandling pågår' },
  MANUALLY_CHANGED:     { code: 32, name: 'M', longName: 'Manuellt uppdaterad' },
  M10:                  { code: 64, name: '10m', longName: '10-månadershyra' },
  EG:                   { code: 128, name: 'E', longName: 'Egensatt hyra', colouredName: '<b class="red-text">E</b>' },
  B:                    { code: 256, name: 'B', longName: 'Blockuthyrning', }
});

app.constant('SettingTypes', {
  NUMBER: 0,
  STRING: 1,
  BOOL: 2,
  TEXT: 3,
  RANGE2NUMBER: 10,
  JSON: 11,
});

app.constant('ReportParameterTypes', {
  NUMBER: 1,
  STRING: 2,
  RANGE:  5,
  COLLECTION: 6,
  YEAR: 10,
  NEGOTIATION_STATUS: 20,
  NEGOTIATION_STATUS_GROUP: 21,
  NEGOTIATION_TYPE: 25,
  HGF_OFFICE: 30,
  HGF_OFFICE_MULTIPLE: 33,
  REGION: 40,
  REGION_MUNICIPALITY: 45,
  MUNICIPALITY: 50,
  KITCHEN_TYPE: 60,
  APARTMENT_STATUS: 62,
});

app.constant('ReportChartTypes', {
  BAR: 1,
  LINE: 2,
});

app.constant('AttachmentTypes', {
  NEGOTIATION_ATTACHMENT: { id: 10, name: 'Bilaga' },
  DRAFT_AGREEMENT:        { id: 11, name: 'Överenskommelse (utkast)' },
  SIGNED_AGREEMENT:       { id: 12, name: 'Underskrivet avtal' },
  RECEIPT:                { id: 30, name: 'Förhandlingsframställan kvitto' },
  RENT_LIST:              { id: 35, name: 'Hyreslista (källfil)' },
  PROPERTY_LIST:          { id: 36, name: 'Fastighetslista (källfil)' },
  AGREEMENT_ATTACHMENT:   { id: 77, name: 'Bilaga överenskommelse' },
});
})();