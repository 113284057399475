(function () {
'use strict';

angular.module('hyresgastApp').controller('ConfirmationBlockedModalCtrl', ConfirmationBlockedModalCtrl);

function ConfirmationBlockedModalCtrl($uibModalInstance, settings) {
  var vm = this;
  vm.subtitle = settings.subtitle;
  vm.showDropdown = settings.showDropdown;
  vm.blockingAuthorityChoices = [
    {
      id: 0,
      name: 'Hyresnämnden '
    },
    {
      id: 1,
      name: 'HMK'
    },
    {
      id: 2,
      name: 'Ingen'
    }
  ];

  vm.confirm = function() {
    $uibModalInstance.close(vm.blockedBy);
  };

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}

})();