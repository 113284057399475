(function () {
'use strict';
angular.module('hyresgastApp').controller('AgreementFigurantsModalCtrl', AgreementFigurantsModalCtrl);

const RoleNames = {
  0: 'Ägare',
  1: 'Hyresvärd',
  2: 'Ombud',
};

function AgreementFigurantsModalCtrl($uibModalInstance, $document,
  Agreement,
  Limits,
  agreement, caseNumber) {
  var vm = this;
  vm.Limits = Limits;
  vm.agreement = agreement;
  vm.caseNumber = caseNumber;

  let source = (agreement.conditionTags.figurants && agreement.conditionTags.figurants.length)
    ? agreement.conditionTags.figurants
    : agreement.owners;
  vm.figurants = source.map(s => {
    let figurant = {
      id: s.id,
      name: s.name,
      organizationNumber: s.organizationNumber,
      role: (typeof s.role === 'undefined') ? 0 : s.role,
    }
    figurant.roleStr = RoleNames[figurant.role];
    return figurant;
  });

  let refreshTableau = id => angular.element($document[0].querySelector("#"+id))
    .scope().$broadcast('refresh');

  vm.columnsAllFigurants = [
    { title: 'Namn',        column: 'name' },
    { title: 'Org nummer',  column: 'organizationNumber' },
    { title: 'Typ',         column: 'roleStr' },
    { title: 'Fastigheter', column: 'properties', type: 'array' },
  ];

  vm.columnsFigurants = [
    { title: 'Namn',        column: 'name' },
    { title: 'Org nummer',  column: 'organizationNumber' },
    { title: 'Typ',         column: 'roleStr' },
  ];

  vm.actionsAllFigurants = [
    {
      title: 'Lägg till',
      action: () => {
        let currentOrganizationNumbers = vm.figurants.map(x => x.organizationNumber);
        let newValues = vm.selectedAllFigurants.filter(x => !currentOrganizationNumbers.includes(x.organizationNumber));
        vm.figurants.push(...newValues);
        refreshTableau('tableauFigurants');
      }
    }
  ];

  vm.actionsFigurants = [
    {
      btnClass: 'btn--icon btn-fu btn--icon-bin',
      action: () => {
        let selectedOrganizationNumbers = vm.selectedFigurants.map(x => x.organizationNumber);
        vm.figurants = vm.figurants.filter(x => !selectedOrganizationNumbers.includes(x.organizationNumber));
        refreshTableau('tableauFigurants');
      }
    },
    {
      title: 'Ny avtalspart',
      common: true,
      action: () => {
        vm.unterpopupNewFigurant = true;
      },
    }
  ];

  vm.createNewFigurant = () => {
    let newFigurant = {
      name: vm.newFigurantName,
      organizationNumber: vm.newFigurantOrganizationNumber.replace('-', ''),
      role: 0,
      roleStr: RoleNames[0],
    };
    vm.figurants.push(newFigurant);
    vm.unterpopupNewFigurant = false;
    vm.newFigurantName = vm.newFigurantOrganizationNumber = null;
    refreshTableau('tableauFigurants');
  }

  vm.selectedFigurants = [];
  vm.selectedAllFigurants = [];

  vm.getFigurants = function () {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
      resolve({ rows: vm.figurants, count: vm.figurants.length });
    });
  }

  vm.getAllFigurants = function() {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
      Agreement.getFigurants({ agreementId: agreement.id })
        .$promise.then(response => {
          response.figurants.forEach(x => {
            x.roleStr = RoleNames[x.role];
            x.properties = x.properties.map(p => `${p.propertyDesignation} ${p.municipality}`);
          });
          resolve({
            rows: response.figurants,
            count: response.figurants.length,
          });
        });
    });
  }

  vm.ok = () => $uibModalInstance.close({
    figurants: vm.figurants.map(f => ({
      id: f.id,
      name: f.name,
      organizationNumber: f.organizationNumber,
      role: f.role,
    })),
  });

  vm.delete = () => $uibModalInstance.close({ figurants: null });
}
})();