(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowRealEstateActiveNegotiationsCtrl', ShowRealEstateActiveNegotiationsCtrl);

function ShowRealEstateActiveNegotiationsCtrl(
  $scope, $filter, $location, $routeParams,
  NegotiationStates, NegotiationTypes,
  HelperService, HyUtils,
  RealEstate, Negotiation,
  ErrorHandler) {

  var vm = this;
  vm.property = null;
  vm.stateSelected = null
  vm.typeSelected = null;
  vm.typeChoices = angular.extend({}, NegotiationTypes);

  vm.stateChoices = {
    IN_PREPARATION: NegotiationStates.IN_PREPARATION,
    SETTLEMENT: NegotiationStates.SETTLEMENT,
    IN_PROGRESS: NegotiationStates.IN_PROGRESS,
    BLOCKED: NegotiationStates.BLOCKED,
    PARTIALLY_BLOCKED: NegotiationStates.PARTIALLY_BLOCKED,
    NEW: NegotiationStates.NEW,
    AGREED: NegotiationStates.AGREED,
    PARTIALLY_AGREED: NegotiationStates.PARTIALLY_AGREED
  };

  vm.sortColumn = $location.search().order || 'caseNumber';
  vm.sortDirection = $location.search().direction || 'ASC';
  vm.listColumns = [
    {
      title: 'Id',
      column: 'caseNumber'
    },
    {
      title: 'Namn',
      column: 'name'
    },
    {
      title: 'Organisation',
      column: 'counterpartOrganization.name'
    },
    {
      title: 'Förhandlingstyp',
      column: 'type'
    },
    {
      title: 'Ansvarig hos HGF',
      column: 'hgfNegotiatior.fullName'
    },
    {
      title: 'Ansvarig hos organisation',
      column: 'extNegotiator.fullName'
    },
    {
      title: 'Status',
      column: 'state'
    },
  ];


  $scope.$parent.$watch('vm.property', property => {
    if (property) {
      vm.property = property;
      vm.getNegotiationsForProperty(property);
    }
  });

  vm.editComment = negotiation => {
    HyUtils.showDefaultModal('EditCommentModalCtrl',
      'resources/agreement/rentlist/edit/edit-comment-modal.html',
      { comment: negotiation.comment, multipleComments: '' },
      result => {
        if (result.delete || result.comment) {
          negotiation.comment = result.delete ? '' : result.comment;
          Negotiation.saveComments([{
            negotiationId: negotiation.id,
            comment: negotiation.comment,
          }]);
          vm.isDirty = true;
        }
      }, null, null,
      { size: 'md' });
  };

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      vm.filteredNegotiations.sort(HelperService.dynamicSortSwe(vm.sortColumn, vm.sortDirection));
    }
  });

  $scope.$watchGroup([function() {
    return vm.typeSelected;
  }, function() {
    return vm.stateSelected;
  }], function(newValues, oldValues) {
    if (!angular.equals(newValues, oldValues)) {
      vm.applyDropdownFilters();
    }
  });

  vm.setStateChoicesModel = function() {
    vm.stateChoicesModel = [{
      id: null,
      name: "All",
      displayName: "Alla"
    }];

    for (var key in vm.stateChoices) {
      if (Object.hasOwnProperty.call(vm.stateChoices, key)) {
        if(vm.stateChoices[key] != null) {
          vm.stateChoicesModel.push({
            id: vm.stateChoices[key],
            name: key,
            displayName: $filter('hyNegotiationStates')(vm.stateChoices[key])
          });
        }
      }
    }
  };

  vm.setTypeChoicesModel = function() {
    vm.typeChoicesModel = [{
      id: null,
      name: "All",
      displayName: "Alla"
    }];

    for (var key in vm.typeChoices) {
      if (Object.hasOwnProperty.call(vm.typeChoices, key)) {
        vm.typeChoicesModel.push({
          id: vm.typeChoices[key],
          name: key,
          displayName: $filter('hyNegotiationTypes')(vm.typeChoices[key])
        });
      }
    }
  };

  vm.getNegotiationsForProperty = function() {
    var stateChoices = Object.values(vm.stateChoices);
    RealEstate.queryNegotiationsForProperty({
      id: $routeParams.id,
      newPropertyIds: vm.property.newPropertyIds,
      'filters[state]': stateChoices,
      order: vm.sortColumn
    })
      .$promise.then(function(response) {
        vm.negotiations = response.rows;
        vm.filteredNegotiations = response.rows;
        vm.negotiationsCount = response.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta förhandlingar.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  // This filter works in combination with the filter pipe used in the html
  vm.applyDropdownFilters = function() {
    vm.filteredNegotiations = vm.negotiations.filter(function(x) {
      return (vm.typeSelected && vm.typeSelected.id != null ? x.type === vm.typeSelected.id : true) &&
             (vm.stateSelected && vm.stateSelected.id != null ? x.state === vm.stateSelected.id : true);
    });
  };

  vm.setStateChoicesModel();
  vm.setTypeChoicesModel();
}


})();